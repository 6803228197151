var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "customlesson layout-border",
      on: {
        click: function ($event) {
          return _vm.numClick(_vm.numBers)
        },
      },
    },
    [
      _c(
        "div",
        { staticClass: "customlesson-content position-relative" },
        [
          _c("PageButton", {
            attrs: {
              bottomCornerButtonList: _vm.bottomCornerButtonList,
              isLastStep: _vm.isLastStep,
            },
            on: { clickNext: _vm.nextPage },
          }),
          _c("SceneSwitchThumbnails", {
            attrs: { buttons: _vm.buttonList, numBers: _vm.numBers },
            on: { changeTheNumbers: _vm.changeTheNumbers },
          }),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.numBers === 1,
                  expression: "numBers === 1",
                },
              ],
              staticClass: "customlesson-picture-switching",
            },
            [
              _c("img", {
                attrs: {
                  src: require("@/assets/img/16-GAMES/G13-stage-2/background-dongdong.svg"),
                  alt: "",
                },
              }),
            ]
          ),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.numBers === 2,
                  expression: "numBers === 2",
                },
              ],
              staticClass: "customlesson-picture-switching",
            },
            [
              _c("img", {
                attrs: {
                  src: require("@/assets/img/16-GAMES/G13-stage-2/background-bb.svg"),
                  alt: "",
                },
              }),
            ]
          ),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.numBers === 3,
                  expression: "numBers === 3",
                },
              ],
              staticClass: "customlesson-picture-switching",
            },
            [
              _c("img", {
                attrs: {
                  src: require("@/assets/img/16-GAMES/G13-stage-2/background-godzilla.svg"),
                  alt: "",
                },
              }),
            ]
          ),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.numBers === 4,
                  expression: "numBers === 4",
                },
              ],
              staticClass: "customlesson-picture-switching",
            },
            [
              _c("img", {
                attrs: {
                  src: require("@/assets/img/16-GAMES/G13-stage-2/background-kingkong.svg"),
                  alt: "",
                },
              }),
            ]
          ),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.numBers === 5,
                  expression: "numBers === 5",
                },
              ],
              staticClass: "customlesson-picture-switching",
            },
            [
              _c("img", {
                attrs: {
                  src: require("@/assets/img/16-GAMES/G13-stage-2/background-panda.svg"),
                  alt: "",
                },
              }),
            ]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }