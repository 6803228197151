<template>
  <div class="syllavus-A-cotainer">
    <SyllabusPage :wordList="wordListData" :isPageA="true" />
  </div>
</template>

<script>
import SyllabusPage from "@/components/Course/CoursePage/SyllabusPage";

export default {
  name: "SyllabusPage-lesson-1-A",
  data() {
    return {
      wordListData: [
        {
          title: "听",
          isUse: false,
          content: [
            {
              pinyin:"nénggòu lǐjiě tīng dào de Zhōngwén cíhuì、duǎnyǔ hé jùzi",
              hanzi: "能够理解听到的中文词汇、短语和句子",
              symbol: "",
              group: 0,
              index: 0,
              symbolType: 0,
            },
          ],
        },
        {
          title: "说",
          isUse: true,
          contentList: [
            [
              {
                pinyin: "zài yóuxì hé qíngjǐng yòng jiǎndān jùzi jìnxíng xúnwèn hé huídá",
                hanzi: "在游戏和情景用简单句子进行询问和回答",
                symbol: "",
                group: 1,
                line: 0,
                index: 0,
                symbolType: 0,
              },
            ],
          ],
        },
        {
          title: "读",
          isUse: false,
          content: [
            {
              pinyin:"nénggòu cóng shēnghuó chǎngjǐng zhōng shíbié jí lǐjiě ",
              hanzi: "能够从生活场景中识别及理解",
              symbol: "",
              group: 1,
              line: 1,
              index: 0,
              symbolType: 0,
            },
            {
              pinyin:"suǒ xué shēngcí hé jùzi; ",
              hanzi: "所学生词和句子",
              symbol: "",
              group: 1,
              line: 1,
              index: 0,
              symbolType: 0,
            },
            {
              pinyin:"liǎojiě yǔ rìcháng xiàngguān de Zhōngguó wénhuà",
              hanzi: "了解与日常相关的中国文化",
              symbol: "",
              group: 1,
              line: 1,
              index: 0,
              symbolType: 0,
            },
            // {
            //   pinyin:"cóng shēnghuó chǎngjǐng",
            //   hanzi: "从生活场景",
            //   symbol: "",
            //   group: 1,
            //   line: 1,
            //   index: 0,
            //   symbolType: 0,
            // },
            // {
            //   pinyin:"zhōng",
            //   hanzi: "中",
            //   symbol: "",
            //   group: 1,
            //   line: 1,
            //   index: 0,
            //   symbolType: 0,
            // },
            // {
            //   pinyin:"zhōng shíbié zhèxiē xìnxī",
            //   hanzi: "中识别这些信息",
            //   symbol: "",
            //   group: 1,
            //   line: 1,
            //   index: 0,
            //   symbolType: 0,
            // },

            // {
            //     pinyin: "",
            //     hanzi: "",
            //     symbol: "；",
            //     group: 1,
            //     line: 1,
            //     index: 1,
            //     symbolType: 2,
            //   },
            //   {
            //   pinyin:"liǎo jiě hàn zì zì yuán",
            //   hanzi: "了解汉字字源",
            //   symbol: "",
            //   group: 1,
            //   line: 1,
            //   index: 0,
            //   symbolType: 0,
            // },
            //  {
            //   pinyin:"hé yǔ rìcháng xiāngguān de zhōngguó wénhuà",
            //   hanzi: "和与日常相关的中国文化",
            //   symbol: "",
            //   group: 1,
            //   line: 1,
            //   index: 0,
            //   symbolType: 0,
            // },
            
          ],
        },
        {
          title: "写",
          isUse: false,
          content: [
            {
              pinyin: "zài liànxí hé yóuxì zhōng,",
              hanzi: "在练习和游戏中,",
              symbol: "",
              group: 1,
              line: 1,
              index: 0,
              symbolType: 0,
            },
            {
              pinyin: "liǎojiě hànzì zì yuán,",
              hanzi: "了解汉字字源,",
              symbol: "",
              group: 1,
              line: 1,
              index: 1,
              symbolType: 0,
            },
            {
              pinyin: "zhǎngwò jīběn jiégòu jí shūxiě ",
              hanzi: "掌握基本结构及书写",
              symbol: "",
              group: 1,
              line: 1,
              index: 2,
              symbolType: 0,
            },
            
          ],
        },
      ],
    };
  },
  components: {
    SyllabusPage,
  },
};
</script>

<style lang="scss" scoped>
.syllavus-A-cotainer {
  width: 100%;
  height: 100%;
}
</style>