<template>
  <div class="game-container">
    <FindElementByPicGame
      :asideElementInfo="asideElementInfo"
      :questionInfoList="questionInfoList"
      :totalStars="totalStars"
      :isNumberGame="false"
      :background="`blue`"
    />
  </div>
</template>

<script>
import FindElementByPicGame from "@/components/Course/GamePage/FindElementByPicGame";
export default {
  data() {
    return {
      totalStars: 6,
      questionInfoList: [{
          id: 1,
          bgImg: require("@/assets/img/12-Vocabulary/lesson-09-mama.svg"),
          sound: require("@/assets/audio/chapter_1/mama.mp3")
        },
        {
          id: 2,
          bgImg: require("@/assets/img/12-Vocabulary/lesson-13-jiejie.svg"),
          sound: require("@/assets/audio/chapter_1/jiejie.mp3")
        },
         {
          id: 3,
          bgImg: require("@/assets/img/12-Vocabulary/lesson-13-didi.svg"),
          sound: require("@/assets/audio/chapter_1/didi.mp3")
        },
         {
          id: 4,
          bgImg: require("@/assets/img/12-Vocabulary/lesson-11-gege.svg"),
          sound: require("@/assets/audio/chapter_1/gege.mp3")
        },
        {
          id: 5,
          bgImg: require("@/assets/img/12-Vocabulary/lesson-09-baba.svg"),
          sound: require("@/assets/audio/chapter_1/baba.mp3")
        },

        
       
        
       
        {
          id: 6,
          bgImg: require("@/assets/img/12-Vocabulary/lesson-11-meimei.svg"),
          sound: require("@/assets/audio/chapter_1/meimei.mp3")
        }
      ],
      asideElementInfo: {
        leftList: [
          {
            id: 5,
            element: "起床",
            pinyin: "qǐchuáng  "
          },
          {
            id: 1,
            element: "睡觉",
            pinyin: "shuìjiào "
          },
          {
            id: 4,
            element: "电视",
            pinyin: "diànshì "
          }
        ],
        rightList: [
          {
            id: 2,
            element: "球",
            pinyin: "qiú "
          },
          {
            id: 3,
            element: "骑马",
            pinyin: "qímǎ "
          },
          {
            id: 6,
            element: "玩",
            pinyin: "wán"
          }
        ]
      }
    };
  },
  components: {
    FindElementByPicGame
  }
};
</script>

<style lang="scss" scoped>
.game-container {
  width: 100%;
  height: 100%;
}
</style>
