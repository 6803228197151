<template>
  <div class="find-stroke-review-container layout-border">
    <PageButton
      :bottomCornerButtonList="bottomCornerButtonList"
      :isLastStep="isLastStep"
      @clickNext="nextPage"
      @updateNumbers="updateNumbers($event)"
    ></PageButton>
    <div class="find-stroke-box">
      <div class="find-stroke-content">
        <div class="find-stroke-area">
          <!-- left 左边 -->
          <div class="find-stroke-content-left">
            <div class="left-bg-img-content">
              <img :src="gridList.backImg" alt="" />
              <div
                class="stroke-img"
                v-for="(item, index) in strokeType"
                :key="index + 'strokeImg'"
              >
                <img
                  v-for="(ele, indexSon) in singleStrokeList[index]"
                  :key="indexSon + 'son'"
                  class="strokeImg"
                  v-show="ele.found"
                  :src="ele.image"
                />
              </div>

              <div :class="'wrapper-' + lessonNum">
                <div
                  class="click-wrapper"
                  v-for="(item, index) in singleStrokeList"
                  :key="index + 'click'"
                  v-show="currentIndex === index"
                  :class="{
                    blueStroke: index === 0,
                    redStroke: index === 1,
                    yellowStroke: index === 2,
                  }"
                >
                  <div
                    class="singleClick"
                    v-for="(ele, indexSon) in singleStrokeList[index]"
                    :key="indexSon + 'single'"
                    :class="`stroke-${indexSon}`"
                  >
                    <div class="line1" @click="strokeClicked(index, indexSon)">
                      <div class="line2" ></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- right 右边 -->
          <div class="find-stroke-content-right">
            <div class="hanziFoundWrapper">
              <div
                v-for="(item, index) in strokeInfoList"
                class="hanziFoundCont"
                :key="index"
              >
                <img :src="item.image" />
                <span> {{ item.found }} / {{ item.total }} </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PageButton from "@/components/Course/Interaction/Button/pageButton.vue";
import { startConfetti, playCorrectSound } from "@/utils/tools.js";

export default {
  name: "FindStrokeReviewGame",
  props: {
    strokeInfoList: {
      type: Array,
      require: true,
      default: () => [],
    },
    singleStrokeList: {
      type: Array,
      require: true,
      default: () => [],
    },
    answerStepsArray: {
      type: Array,
      require: true,
      default: () => [],
    },
    strokeType: {
      type: Number,
      require: true,
    },
    gridList: {
      type: Object,
      require: true,
    },
    lessonNum: {
      type: Number,
      require: true,
    },
  },
  computed: {
    allStrokeImgList() {
      let imgArr = [];
      this.singleStrokeList.forEach((item) => {
        imgArr.push(...item);
      });
      return imgArr;
    },
  },
  data() {
    return {
      clickSocketIfo: {},
      currentIndex: 0,
      totalStrokesFound: 0,
      isLastStep: false,
      success_audio_sound: require("@/assets/audio/correct_answer.mp3"),
      bottomCornerButtonList: {
        settings: {
          showNextOnLastStep: true,
        },
        buttons: [
          {
            type: "look",
            startNumberRange: 1,
            endNumberRange: 3,
          },
        ],
      },
    };
  },
  components: {
    PageButton,
  },
  watch: {
    clickSocketIfo: {
      handler(value) {
        const data = {
          clickType: 20034,
          data: { value },
          text: "FindStrokeReviewGame点击socket",
        };
        this.sendSocketInfo = { ...data };
        this.$bus.$emit("kids_webSocket_sendInfo", this.sendSocketInfo);
      },
      deep: true,
    },
  },
  mounted() {
    console.log(this.gridList);
    this.$bus.$on("clickStrokeReview", ({ stroke, index }) => {
      this.strokeClicked(stroke, index, true);
    });
  },
  methods: {
    nextPage() {
      this.$bus.$emit("nextButton", true);
    },
    strokeClicked(stroke, index, isFromSocket = false) {
      if (!isFromSocket) {
        this.clickSocketIfo = {
          stroke,
          index,
          value: Math.random(),
        };
      }
      if (this.singleStrokeList[stroke][index].found == false) {
        this.singleStrokeList[stroke][index].found = true;
        this.strokeInfoList[stroke].found++;
        this.totalStrokesFound++;
        if (
          this.totalStrokesFound === this.answerStepsArray[this.currentIndex]
        ) {
          playCorrectSound();
          this.currentIndex++;
        }
      }

      if (this.currentIndex === 3) {
        startConfetti();
        this.isLastStep = true;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.hanziFoundCont {
  display: flex;
  img {
    width: 11vh !important;
    background-color: white;
    border-bottom-left-radius: 15px;
    border-top-left-radius: 15px;
  }
  span {
    background-color: white;
    /* height: 94%; */
    align-self: center;
    font-size: 5vh;
    padding: 2vh;
    border-bottom-right-radius: 20px;
    border-top-right-radius: 20px;
    color: #224e96;
  }
}
.strokeImg {
  position: absolute;
  /* width: 100%; */
  height: 100%;
  left: 0;
}
.wrapper-8 {
  .click-wrapper {
    top: 0;
    height: 100%;
    width: 100%;
    position: absolute;
    .singleClick {
      width: 33%;
      height: 33%;
      position: absolute;
    }
  }
  .blueStroke {
    .singleClick {
      left: 0;
      margin-top: 33%;
      .line1 {
        height: 13%;
        width: 53%;
        top: 29%;
        left: 27%;
        // background-color: blue;
        position: relative;
        cursor: pointer;
      }
      .line2 {
        height: 246%;
        width: 30%;
        // background-color: blue;
        position: relative;
        margin-left: 55%;
      }
    }
    .stroke-1 {
      left: 33%;
      margin-top: 38%;
    }
    .stroke-2 {
      left: 65%;
      margin-top: 33%;
    }
    .stroke-3 {
      left: 68%;
      margin-top: 63%;
    }
  }
  .redStroke {
    .singleClick {
      margin-top: 0%;
      .line1 {
        height: 59%;
        width: 13%;
        top: 20%;
        left: 46%;
        // background-color: yellow;
        position: relative;
        cursor: pointer;
      }
    }
    .stroke-1 {
      margin-top: 33%;
      .line1 {
        top: 31%;
        left: 27%;
        height: 39%;
      }
    }
    .stroke-2 {
      margin-top: 33%;
      left: 31%;
      transform: rotate(15deg);
    }
    .stroke-3 {
      margin-top: 33%;
      left: 62%;
      transform: rotate(15deg);
    }
    .stroke-4 {
      margin-top: 67%;
      .line1 {
        top: 41%;
        left: 43%;
        height: 39%;
      }
    }
    .stroke-5 {
      width: 10%;
      margin-top: 67%;
      left: 36%;
      .line1 {
        width: 30%;
        margin-top: 67%;
        left: 81%;
        height: 40%;
      }
    }
    .stroke-6 {
      width: 10%;
      margin-top: 67%;
      left: 50%;
      .line1 {
        top: 30%;
        left: 6%;
        height: 39%;
        width: 36%;
      }
    }
    .stroke-7 {
      margin-top: 67%;
      left: 67%;
      .line1 {
        top: 22%;
        left: 31%;
        height: 36%;
      }
    }
  }
  .yellowStroke {
    .singleClick {
      margin-top: 0%;
      left: 29%;
      transform: rotate(15deg);
      .line1 {
        height: 59%;
        width: 13%;
        top: 20%;
        left: 46%;
        // background-color: red;
        position: relative;
        cursor: pointer;
      }
    }
    .stroke-1 {
      left: 62%;
    }
    .stroke-2 {
      margin-top: 62%;
      left: -5%;
      transform: rotate(34deg);
    }
    .stroke-3 {
      margin-top: 62%;
      left: 29%;
      transform: rotate(34deg);
    }
    .stroke-4 {
      margin-top: 70%;
      left: 65%;
      transform: rotate(12deg);
    }
  }
}
.wrapper-16 {
  .click-wrapper {
    top: 0;
    height: 100%;
    width: 100%;
    position: absolute;
    .singleClick {
      width: 33%;
      height: 33%;
      position: absolute;
    }
  }
  .blueStroke {
    .singleClick {
      left: 37%;
      margin-top: 3%;
      .line1 {
        height: 6%;
        width: 23%;
        top: 29%;
        left: 27%;
        // background-color: blue;
        position: relative;
        cursor: pointer;
      }
      .line2 {
        height: 540%;
        width: 30%;
        // background-color: blue;
        position: relative;
        margin-left: 55%;
        transform: rotate(21deg);
      }
    }

    .stroke-1 {
      left: 10%;
      width: 7%;
      height: 44%;
      margin-top: 26%;
      transform: rotate(44deg);
      .line1 {
        height: 6%;
        width: 56%;
        top: 29%;
        left: 27%;
        position: relative;
        cursor: pointer;
      }
      .line2 {
        height: 540%;
        width: 40%;
        position: relative;
        margin-left: 55%;
        transform: rotate(21deg);
      }
    }
    .stroke-2 {
      left: 11.5%;
      width: 8%;
      height: 40%;
      margin-top: 36%;
      transform: rotate(45deg);
      .line1 {
        height: 6%;
        width: 36%;
        top: 29%;
        left: 27%;
        position: relative;
        cursor: pointer;
      }
      .line2 {
        height: 540%;
        width: 60%;
        position: relative;
        margin-left: 55%;
        transform: rotate(21deg);
      }
    }
    .stroke-3 {
      left: 43%;
      height: 30%;
      width: 20%;
      margin-top: 42%;
      transform: rotate(20deg);
    }
    .stroke-4 {
      left: 0%;
      width: 30%;
      margin-top: 67%;
    }
    .stroke-5 {
      left: 73%;
      margin-top: 65%;
      .line2 {

        transform: rotate(40deg);

      }
    }
  }
  .redStroke {
   .singleClick {
      left: 72%;
      margin-top: 1%;
      .line1 {
        height: 6%;
        width: 26%;
        top: 29%;
        left: 27%;
        // background-color: red;
        position: relative;
        cursor: pointer;
      }
      .line2 {
        height: 640%;
        width: 28%;
        // background-color: red;
        position: relative;
        margin-left: 70%;
        transform: rotate(8deg);
      }
    }
    .stroke-1 {
      // background: rgba(182, 182, 182,0.5);
      padding: 0px;
      margin-top: 32%;
      left: 87%;
      width: 4%;
      .line1 {
        height: 6%;
        width: 50%;
        top: 29%;
        left: 8%;
        // background-color: red;
        position: relative;
        cursor: pointer;
      }
      .line2 {
        height: 700%;
        width: 68%;
        // background-color: red;
        position: relative;
        margin-left: 60%;
        transform: rotate(0deg);

      }
    }
    .stroke-2 {
      // background: rgba(182, 182, 182,0.5);
      padding: 0px;
      margin-top: 32%;
      left: 79%;
      width: 8%;
      .line1 {
        height: 6%;
        width: 50%;
        top: 29%;
        left: 8%;
        position: relative;
        cursor: pointer;
      }
      .line2 {
        height: 300%;
        width: 68%;
        position: relative;
        margin-left: 92%;
        margin-top: 170%;
        transform: rotate(0deg);

      }
    }
    .stroke-3 {
      margin-top: 69%;
      left: 7.5%;
    }
    .stroke-4 {
      margin-top: 75%;
      left: 71%;
       .line2 {
        height: 440%;
        width: 28%;
        position: relative;
        margin-left: 70%;
      }
    }
   
    
  }
  .yellowStroke {
    .singleClick {
      margin-top: 66%;
      left: 66%;
      .line1 {
        height: 6%;
        width: 23%;
        top: 29%;
        left: 27%;
        // background-color: yellow;
        position: relative;
        cursor: pointer;
        transform: rotate(-70deg);
      }
      .line2 {
        height: 340%;
        width: 28%;
        // background-color: yellow;
        position: relative;
        margin-left: -20%;
        transform: rotate(25deg);

      }
    }
    .stroke-1 {
      left: 30%;
      margin-top: 37%;
      .line1 {
        height: 6%;
        width: 29%;
        top: 29%;
        left: 27%;
        // background-color: yellow;
        position: relative;
        cursor: pointer;
        transform: rotate(-80deg);
      }
    }
    .stroke-2 {
      margin-top: 74%;
      left: 35%;
      .line2 {
        height: 540%;
        width: 29%;
        // background-color: yellow;
        position: relative;
        margin-left: -20%;
        transform: rotate(0deg);

      }
    }
    .stroke-3 {
      margin-top: 5.5%;
      left: 2%;
      .line2 {
        height: 540%;
        width: 29%;
        // background-color: yellow;
        position: relative;
        margin-left: -20%;
        transform: rotate(0deg);
      }
    }
   
  }
}

.wrapper-24 {
  .click-wrapper {
    top: 0;
    height: 100%;
    width: 100%;
    position: absolute;
    .singleClick {
      width: 33%;
      height: 10%;
      position: absolute;
    }
  }
  .blueStroke {
    .singleClick {
      left: -9%;
      margin-top: 4%;
      .line1 {
        height: 76%;
        width: 36%;
        top: 29%;
        left: 27%;
        // background-color: blue;
        // opacity: 0.5;
        position: relative;
        cursor: pointer;
        
      }
    }

    .stroke-1 {
      left: 9%;
      width: 7%;
      margin-top: 10%;
      .line1 {
        height: 70%;
        width: 56%;
        top: 30%;
        left: 8%;
        position: relative;
        cursor: pointer;
        transform: rotate(40deg);
      }
    }
    .stroke-2 {
      left: 40%;
      width: 7%;
      margin-top: 8%;
        transform: rotate(30deg);
      .line1 {
        height: 70%;
        width: 56%;
        top: 0%;
        left: 18%;
        position: relative;
        cursor: pointer;
        // transform: rotate(30deg);
      }
    }
    .stroke-3 {
      left: 43%;
      width: 7%;
      margin-top: 11%;
        transform: rotate(30deg);

      .line1 {
        height: 90%;
        width: 60%;
        top: 0%;
        left: 16%;
        position: relative;
        cursor: pointer;
      }
    }
    .stroke-4 {
      left: 50%;
      width: 10%;
      margin-top: 14%;
      .line1{
        height: 74%;
        width: 78%;
        left: -16%;
        position: relative;

      }
    }
    .stroke-5 {
      left: 66%;
      margin-top: 5%;
    }
    .stroke-6 {
      left: 12%;
      width: 7%;
      margin-top: 39%;
      .line1 {
        height: 90%;
        width: 66%;
        top: 0%;
        left: 16%;
        position: relative;
        cursor: pointer;
        transform: rotate(40deg);
      }
    }
    .stroke-7 {
      left: 14%;
      width: 7%;
      margin-top: 43%;
      .line1 {
        height: 120%;
        width: 76%;
        top: 0%;
        left: 16%;
        position: relative;
        cursor: pointer;
        transform: rotate(40deg);
      }
    }
    .stroke-8 {
      left: 32%;
      margin-top: 38%;
       .line1 {
        height: 100%;
        width: 30%;
      }
    }
    .stroke-9 {
      left: 66%;
      margin-top: 82%;
    }
  }
  .redStroke {
   .singleClick {
      left: 8%;
      margin-top: 12%;
      .line1 {
        height: 90%;
        width: 28%;
        top: 34%;
        left: 24%;
        // background-color: red;
        // opacity: 0.5;
        position: relative;
        cursor: pointer;
      }
      
    }
    .stroke-1 {
      padding: 0px;
      margin-top: 12%;
      left: 77%;
      width: 50%;
      .line1 {
        height: 95%;
        width: 24%;
        top: 29%;
        left: 8%;
        // background-color: red;
        position: relative;
        cursor: pointer;
      }
     
    }
    .stroke-2 {
      padding: 0px;
      margin-top: 40%;
      left: 49%;
      width: 20%;
      .line1 {
        height: 140%;
        width: 50%;
        top: 29%;
        left: 8%;
        position: relative;
        cursor: pointer;
      }

    }
    .stroke-3 {
      margin-top: 73%;
      left: 42%;
    }

  }
  .yellowStroke {
    .singleClick {
      margin-top: 43%;
      left: 62%;
      transform: rotate(-90deg);
      
      .line1 {
        height: 40%;
        width: 40%;
        top: 29%;
        left: 27%;
        // background-color: yellow;
        // opacity: 0.5;
        position: relative;
        cursor: pointer;
        
      }
      .line2 {
        height: 320%;
        width: 30%;
        // background-color: yellow;
        position: relative;
        margin-left: -20%;
        transform: rotate(0deg);
      }
    }
    .stroke-1 {
      left: 6%;
      width: 20%;
      height: 30%;
      margin-top: 67%;
      .line1 {
        height: 10%;
        width: 38%;
        top: 29%;
        left: 27%;
        // background-color: yellow;
        position: relative;
        cursor: pointer;
      }
       .line2 {
        width: 45%;
        // background-color: yellow;
        position: relative;
      }
    }
    .stroke-2 {
      margin-top: 76%;
      left: 28%;
    }
   
  }
}
.wrapper-32 {
  .click-wrapper {
    top: 0;
    height: 100%;
    width: 100%;
    position: absolute;
    .singleClick {
      width: 33%;
      height: 10%;
      position: absolute;
    }
  }
  .blueStroke {
    .singleClick {
      left: 4%;
      margin-top: 10%;
      .line1 {
        height: 116%;
        width: 30%;
        top: 29%;
        left: 27%;
        // background-color: blue;
        // opacity: 0.5;
        position: relative;
        cursor: pointer;
        
      }
    }

    .stroke-1 {
      left: 75%;
      width: 27%;
      margin-top: 42%;
      .line1 {
        height: 140%;
        width: 56%;
        top: 30%;
        left: 8%;
        position: relative;
        cursor: pointer;
      }
    }
    .stroke-2 {
      left: 38%;
      width: 10%;
      margin-top: 80%;
      .line1 {
        height: 110%;
        width: 76%;
        top: 0%;
        left: 18%;
        position: relative;
        cursor: pointer;
        // transform: rotate(30deg);
      }
    }
  
  }
  .redStroke {
   .singleClick {
      left: 70%;
      margin-top: 11%;
      .line1 {
        height: 65%;
        width: 18%;
        top: 34%;
        left: 24%;
        // background-color: red;
        // opacity: 0.5;
        position: relative;
        cursor: pointer;
      }
      
    }
    .stroke-1 {
      margin-top: 37%;
      left: 6%;
      width: 50%;
      .line1 {
        height: 140%;
        width: 24%;
        top: 29%;
        left: 8%;
        // background-color: red;
        position: relative;
        cursor: pointer;
      }
     
    }
    .stroke-2 {
      padding: 0px;
      margin-top: 44%;
      left: 38%;
      width: 20%;
      .line1 {
        height: 70%;
        width: 30%;
        top: 29%;
        left: 8%;
        position: relative;
        cursor: pointer;
      }

    }
    .stroke-3 {
      margin-top: 70%;
      left: 0%;
      width: 33%;
      height: 20%;
    }

  }
  .yellowStroke {
    .singleClick {
      margin-top: 5%;
      left: 32%;
      // transform: rotate(-90deg);
      
      .line1 {
        height: 80%;
        width: 20%;
        top: 29%;
        left: 27%;
        // background-color: yellow;
        // opacity: 0.5;
        position: relative;
        cursor: pointer;
      }
    }
    .stroke-1 {
      left: 64%;
      margin-top: 5%;
    }
    .stroke-2 {
      margin-top: 70%;
      left: 74%;
       .line1 {
        height: 120%;
        width: 30%;
        position: relative;
        cursor: pointer;
      }
    }
   
  }
}
.wrapper-40 {
  .click-wrapper {
    top: 0;
    height: 100%;
    width: 100%;
    position: absolute;
    .singleClick {
      width: 13%;
      height: 10%;
      position: absolute;
    }
  }
  .blueStroke {
    .singleClick {
      left: 72%;
      width: 8%;
      margin-top: 12%;
      .line1 {
        height: 96%;
        width: 62%;
        top: 29%;
        left: 35%;
        // background-color: blue;
        // opacity: 0.5;
        position: relative;
        cursor: pointer;
      }
    }

    .stroke-1 {
      left: 42%;
      // width: 23%;
      // height: 20%;
      margin-top: 34%;
      .line1 {
        height: 200%;
        width: 66%;
        top: 35%;
        left: 26%;
        position: relative;
        cursor: pointer;
      }
    }
    .stroke-2 {
      left: 11%;
      width: 7%;
      margin-top: 78%;
      .line1 {
        height: 120%;
        width: 66%;
        top: 0%;
        left: 36%;
        position: relative;
        cursor: pointer;
        // transform: rotate(30deg);
      }
    }
  }
  .redStroke {
   .singleClick {
      left: 9%;
      margin-top: 3%;
      .line1 {
        height: 80%;
        width: 84%;
        top: 34%;
        left: 24%;
        // background-color: red;
        // opacity: 0.5;
        position: relative;
        cursor: pointer;
      }
      
    }
    .stroke-1 {
      padding: 0px;
      margin-top: 4%;
      left: 45%;
      width: 40%;
      .line1 {
        height: 75%;
        width: 24%;
        top: 29%;
        left: 8%;
        // background-color: red;
        position: relative;
        cursor: pointer;
      }
     
    }
    .stroke-2 {
      padding: 0px;
      margin-top: 5%;
      left: 82%;
      width: 20%;
      .line1 {
        height: 80%;
        width: 50%;
        top: 29%;
        left: 8%;
        position: relative;
        cursor: pointer;
      }

    }
    .stroke-3 {
      margin-top: 36%;
      left: 78%;
    }

  }
  .yellowStroke {
    .singleClick {
      margin-top: 14%;
      left: 36%;
      // transform: rotate(-90deg);
      
      .line1 {
        height: 88%;
        width: 50%;
        top: 29%;
        left: 27%;
        // background-color: yellow;
        // opacity: 0.5;
        position: relative;
        cursor: pointer;
        
      }
     
    }
    .stroke-1 {
      left: 0%;
      width: 20%;
      height: 30%;
      margin-top: 40%;
      .line1 {
        height: 26%;
        width: 38%;
        top: 29%;
        left: 27%;
        // background-color: yellow;
        position: relative;
        cursor: pointer;
      }
       
    }
    .stroke-2 {
      margin-top: 48%;
      left: 67%;
      .line1 {
        height: 45%;
        width: 68%;
        top: 40%;
        left: 27%;
        // background-color: yellow;
        position: relative;
        cursor: pointer;
      }
    }
    .stroke-3 {
      margin-top: 76%;
      left: 38%;
      .line1 {
        width: 88%;
        position: relative;
        cursor: pointer;
      }
    }
    .stroke-4 {
      margin-top: 79%;
      left: 68%;
       .line1 {
        height: 60%;
        width: 88%;
        position: relative;
        cursor: pointer;
      }
    }
    .stroke-5 {
      margin-top: 78%;
      left: 0%;
       .line1 {
        width: 78%;
        position: relative;
        cursor: pointer;
      }
    }
  }
}
.wrapper-48 {
  .click-wrapper {
    top: 0;
    height: 100%;
    width: 100%;
    position: absolute;
    .singleClick {
      width: 13%;
      height: 10%;
      position: absolute;
    }
  }
  .blueStroke {
    .singleClick {
      left: 15%;
      width: 8%;
      margin-top: 4%;
      .line1 {
        height: 186%;
        width: 72%;
        top: 29%;
        left: 35%;
        // background-color: blue;
        // opacity: 0.5;
        position: relative;
        cursor: pointer;
      }
    }

    .stroke-1 {
      left: 52%;
      // width: 23%;
      // height: 20%;
      margin-top: 34%;
      .line1 {
        height: 200%;
        width: 66%;
        top: 35%;
        left: 26%;
        position: relative;
        cursor: pointer;
      }
    }
    .stroke-2 {
      left: 14%;
      width: 7%;
      margin-top: 80%;
      .line1 {
        height: 120%;
        width: 66%;
        top: 0%;
        left: 36%;
        position: relative;
        cursor: pointer;
        // transform: rotate(30deg);
      }
    }
     .stroke-3 {
      left: 40%;
      width: 7%;
      margin-top: 73%;
      .line1 {
        height: 180%;
        width: 66%;
        top: 0%;
        left: 36%;
        position: relative;
        cursor: pointer;
        // transform: rotate(30deg);
      }
    }
     .stroke-4 {
      left: 82%;
      width: 7%;
      margin-top: 73%;
      .line1 {
        height: 188%;
        width: 66%;
        top: 0%;
        left: 36%;
        position: relative;
        cursor: pointer;
        // transform: rotate(30deg);
      }
    }
  }
  .redStroke {
   .singleClick {
      left: 42%;
      margin-top: 3%;
      .line1 {
        height: 45%;
        width: 104%;
        top: 34%;
        left: 24%;
        // background-color: red;
        // opacity: 0.5;
        position: relative;
        cursor: pointer;
      }
      
    }
    .stroke-1 {
      padding: 0px;
      margin-top: 3%;
      left: 75%;
    }
    .stroke-2 {
      padding: 0px;
      margin-top: 73%;
      left: 16%;
      width: 10%;
      .line1 {
        height: 50%;
        width: 96%;
        top: 29%;
        left: 8%;
        position: relative;
        cursor: pointer;
      }

    }
    .stroke-3 {
      padding: 0px;
      margin-top: 73%;
      left: 48%;
      width: 10%;
      .line1 {
        height: 50%;
        width: 98%;
        top: 29%;
        left: 8%;
        position: relative;
        cursor: pointer;
      }
    }

  }
  .yellowStroke {
    .singleClick {
      margin-top: 13%;
      left: 75%;
      .line1 {
        height: 104%;
        width: 50%;
        top: 29%;
        left: 27%;
        // background-color: yellow;
        // opacity: 0.5;
        position: relative;
        cursor: pointer;
        
      }
     
    }
    .stroke-1 {
      left: 1%;
      width: 20%;
      height: 30%;
      margin-top: 35%;
      .line1 {
        height: 46%;
        width: 38%;
        top: 29%;
        left: 27%;
        // background-color: yellow;
        position: relative;
        cursor: pointer;
      }
       
    }
    .stroke-2 {
      margin-top: 38%;
      left: 70%;
      .line1 {
        height: 155%;
        width: 54%;
        top: 40%;
        left: 27%;
        // background-color: yellow;
        position: relative;
        cursor: pointer;
      }
    }
    .stroke-3 {
      margin-top: 78%;
      left: 72%;
      .line1 {
        width: 48%;
        position: relative;
        cursor: pointer;
      }
    }
  }
}
.wrapper-56 {
  .click-wrapper {
    top: 0;
    height: 100%;
    width: 100%;
    position: absolute;
    .singleClick {
      width: 13%;
      height: 10%;
      position: absolute;
      
    }
  }
  .blueStroke {
    .singleClick {
      left: 45%;
      width: 8%;
      margin-top: 4%;
      transform: rotate(-25deg);

      .line1 {
        height: 206%;
        width: 72%;
        top: 29%;
        left: 35%;
        // background-color: blue;
        // opacity: 0.5;
        position: relative;
        cursor: pointer;
      }
    }

    .stroke-1 {
      left: 47%;
      // width: 23%;
      // height: 20%;
      margin-top: 36%;
      .line1 {
        height: 200%;
        width: 66%;
        top: 35%;
        left: 26%;
        position: relative;
        cursor: pointer;
      }
    }
    .stroke-2 {
       left:77%;
      // width: 23%;
      // height: 20%;
      margin-top: 36%;
      .line1 {
        height: 210%;
        width: 66%;
        top: 35%;
        left: 26%;
        position: relative;
        cursor: pointer;
      }
    }
     .stroke-3 {
      left: 14%;
      width: 7%;
      margin-top: 73%;
      .line1 {
        height: 200%;
        width: 66%;
        top: 0%;
        left: 36%;
        position: relative;
        cursor: pointer;
        // transform: rotate(30deg);
      }
    }
     .stroke-4 {
      left: 49%;
      width: 7%;
      margin-top: 75%;
      .line1 {
        height: 178%;
        width: 66%;
        top: 0%;
        left: 36%;
        position: relative;
        cursor: pointer;
        // transform: rotate(30deg);
      }
    }
      .stroke-5 {
      left: 79%;
      width: 7%;
      margin-top: 70%;
      .line1 {
        height: 188%;
        width: 66%;
        top: 0%;
        left: 36%;
        position: relative;
        cursor: pointer;
        // transform: rotate(30deg);
      }
    }
  }
  .redStroke {
   .singleClick {
      left: 9%;
      margin-top: 17%;
      .line1 {
        height: 56%;
        width: 94%;
        top: 34%;
        left: 24%;
        // background-color: red;
        // opacity: 0.5;
        position: relative;
        cursor: pointer;
      }
      
    }
    .stroke-1 {
      padding: 0px;
      margin-top: 44%;
      left: 9%;
      .line1 {
        height: 48%;
        width: 74%;
        position: relative;
        cursor: pointer;
      }
    }
    .stroke-2 {
      padding: 0px;
      margin-top: 51%;
      left: 7%;
      width: 10%;
      .line1 {
        height: 50%;
        width: 96%;
        top: 29%;
        left: 8%;
        position: relative;
        cursor: pointer;
      }

    }
    .stroke-3 {
     padding: 0px;
      margin-top: 51%;
      left: 17%;
      width: 10%;
      .line1 {
        height: 50%;
        width: 96%;
        top: 29%;
        left: 8%;
        position: relative;
        cursor: pointer;
      }
    }
     .stroke-4 {
      padding: 0px;
      margin-top: 85%;
      left: 78%;
      width: 10%;
      .line1 {
        height: 50%;
        width: 98%;
        top: 29%;
        left: 8%;
        position: relative;
        cursor: pointer;
      }
    }

  }
  .yellowStroke {
    .singleClick {
      margin-top: 10%;
      left: 68%;
      .line1 {
        height: 104%;
        width: 56%;
        top: 29%;
        left: 27%;
        // background-color: yellow;
        // opacity: 0.5;
        position: relative;
        cursor: pointer;
        
      }
     
    }
    .stroke-1 {
      left: 35%;
      margin-top: 44%;
    }
    .stroke-2 {
      margin-top: 77%;
      left: 2%;
      
    }
    .stroke-3 {
      margin-top: 77%;
      left: 36%;
      
    }
  }
}
.wrapper-64 {
  .click-wrapper {
    top: 0;
    height: 100%;
    width: 100%;
    position: absolute;
    .singleClick {
      width: 13%;
      height: 10%;
      position: absolute;
    }
  }
  .blueStroke {
    .singleClick {
      left: 40%;
      width: 8%;
      margin-top: 5%;
      .line1 {
        height: 82%;
        width: 202%;
        top: 29%;
        left: 35%;
        // background-color: blue;
        // opacity: 0.5;
        position: relative;
        cursor: pointer;
      }
    }

    .stroke-1 {
      left: 46%;
      // width: 23%;
      // height: 20%;
      margin-top: 46%;
      .line1 {
        height: 90%;
        width: 150%;
        top: 35%;
        left: 26%;
        position: relative;
        cursor: pointer;
      }
    }
    .stroke-2 {
      left: 76%;
      width: 7%;
      margin-top: 84%;
      .line1 {
        height: 90%;
        width: 180%;
        top: 0%;
        left: 36%;
        position: relative;
        cursor: pointer;
        // transform: rotate(30deg);
      }
    }
    
  }
  .redStroke {
   .singleClick {
      left: 78%;
      margin-top: 6%;
      .line1 {
        height: 45%;
        width: 44%;
        top: 34%;
        left: 24%;
        // background-color: red;
        // opacity: 0.5;
        position: relative;
        cursor: pointer;
      }
      
    }
    .stroke-1 {
      padding: 0px;
      margin-top: 41%;
      left: 17%;
        .line1 {
        height: 80%;
        width: 46%;
        top: 29%;
        left: 8%;
        position: relative;
        cursor: pointer;
      }
    }
    .stroke-2 {
      padding: 0px;
      margin-top: 47%;
      left: 78%;
    }
  }
  .yellowStroke {
    .singleClick {
      margin-top: 3%;
      left: 8%;
      .line1 {
        height: 84%;
        width: 113%;
        top: 29%;
        left: 27%;
        // background-color: yellow;
        // opacity: 0.5;
        position: relative;
        cursor: pointer;
        
      }
     
    }
    .stroke-1 {
      left: 7%;
      margin-top: 70%;
 
    }
    .stroke-2 {
      margin-top: 81%;
      left: 44%;
      
    }
   
  }
}
.find-stroke-review-container {
  .find-stroke-box {
    width: 100%;
    height: 100%;
    background: #fff;
    border-radius: 58px;
    position: relative;
    .find-stroke-content {
      background: #f7f4e0;
      // padding: 10px 10px 30px 10px;
      background-size: cover;
      width: 100%;
      height: 100%;
      border-radius: 58px;
      // display: flex;
      position: relative;
      .find-stroke-area {
        display: flex;
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        justify-content: center;
        .find-stroke-content-left {
          // position: absolute;
          position: relative;
          display: flex;
          width: 50%;
          height: 100%;
          justify-content: flex-end;
          align-items: center;
          .left-bg-img-content {
            position: relative;
            height: 70%;
            img {
              height: 100%;
            }
            .stroke-img {
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
            }
          }
        }
        .find-stroke-content-right {
          width: 30%;
          height: 100%;
          left: 50%;
          display: flex;
          flex-wrap: wrap;
          justify-content: center;
          align-items: center;
          img {
            width: 40%;
            margin: 10px 0;
          }
        }
      }
    }
  }
}
</style>
