<template>
  <div class="game-container">
    <FindStrokeReviewGame
      :strokeInfoList="strokeInfoList"
      :singleStrokeList="singleStrokeList"
      :answerStepsArray="answerStepsArray"
      :strokeType="3"
      :gridList="gridList"
      :lessonNum="16"
    />
  </div>
</template>

<script>
import FindStrokeReviewGame from "@/components/Course/GamePage/FindStrokeReviewGame";
export default {
  data() {
    return {
      answerStepsArray: [6, 11, 15],
      gridList: {
        // backImg: require("@/assets/img/16-GAMES/G55-bingo/lesson-16-bingo/background.svg"),
      },
      strokeInfoList: [
        {
          id: 1,
          // image: require("@/assets/img/16-GAMES/G55-bingo/lesson-16-bingo/blue.svg"),
          total: 6,
          found: 0,
        },
        {
          id: 1,
          // image: require("@/assets/img/16-GAMES/G55-bingo/lesson-16-bingo/red.svg"),
          total: 5,
          found: 0,
        },
        {
          id: 1,
          // image: require("@/assets/img/16-GAMES/G55-bingo/lesson-16-bingo/yellow.svg"),
          total: 4,
          found: 0,
        },
      ],

      singleStrokeList: [
        [
          {
            id: 1,
            // image: require("@/assets/img/16-GAMES/G55-bingo/lesson-16-bingo/blue-1.svg"),
            type: "blue",
            found: false,
          },
          {
            id: 2,
            // image: require("@/assets/img/16-GAMES/G55-bingo/lesson-16-bingo/blue-2-1.svg"),
            type: "blue",
            found: false,
          },
          {
            id: 3,
            // image: require("@/assets/img/16-GAMES/G55-bingo/lesson-16-bingo/blue-2-2.svg"),
            type: "blue",
            found: false,
          },
          {
            id: 4,
            // image: require("@/assets/img/16-GAMES/G55-bingo/lesson-16-bingo/blue-3.svg"),
            type: "blue",
            found: false,
          },
          {
            id: 5,
            // image: require("@/assets/img/16-GAMES/G55-bingo/lesson-16-bingo/blue-4.svg"),
            type: "blue",
            found: false,
          },
          {
            id: 6,
            // image: require("@/assets/img/16-GAMES/G55-bingo/lesson-16-bingo/blue-6.svg"),
            type: "blue",
            found: false,
          },
        ],
        [
          {
            id: 1,
            // image: require("@/assets/img/16-GAMES/G55-bingo/lesson-16-bingo/red-1.svg"),
            type: "red",
            found: false,
          },
          {
            id: 2,
            // image: require("@/assets/img/16-GAMES/G55-bingo/lesson-16-bingo/red-2-1.svg"),
            type: "red",
            found: false,
          },
          {
            id: 3,
            // image: require("@/assets/img/16-GAMES/G55-bingo/lesson-16-bingo/red-2-2.svg"),
            type: "red",
            found: false,
          },
          {
            id: 4,
            // image: require("@/assets/img/16-GAMES/G55-bingo/lesson-16-bingo/red-3.svg"),
            type: "red",
            found: false,
          },
          {
            id: 5,
            // image: require("@/assets/img/16-GAMES/G55-bingo/lesson-16-bingo/red-4.svg"),
            type: "red",
            found: false,
          },
          
        ],
        [
          {
            id: 1,
            // image: require("@/assets/img/16-GAMES/G55-bingo/lesson-16-bingo/yellow-1.svg"),
            type: "yellow",
            found: false,
          },
          {
            id: 2,
            // image: require("@/assets/img/16-GAMES/G55-bingo/lesson-16-bingo/yellow-2.svg"),
            type: "yellow",
            found: false,
          },
          {
            id: 3,
            // image: require("@/assets/img/16-GAMES/G55-bingo/lesson-16-bingo/yellow-3.svg"),
            type: "yellow",
            found: false,
          },
          {
            id: 4,
            // image: require("@/assets/img/16-GAMES/G55-bingo/lesson-16-bingo/yellow-4.svg"),
            type: "yellow",
            found: false,
          },
        ],
      ],
    };
  },
  components: {
    FindStrokeReviewGame,
  },
};
</script>

<style lang="scss" scoped>
.game-container {
  width: 100%;
  height: 100%;
}
</style>
