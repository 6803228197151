<template>
  <div class="game-container">
    <BingoGame
      :asideElementInfo="asideElementInfo"
      :questionInfoList="questionInfoList"
      @changeStatus="changeStatus"
      :lessonType="1"
      :bingoIndexList="[1, 5, 9]"
      :totalStars ="totalStars"

    />
  </div>
</template>

<script>
import BingoGame from "@/components/Course/GamePage/BingoGame";
export default {
  data() {
    return {
      totalStars: 5,
      lesson: 16,
      questionInfoList: [
        {
          id: 2,
          pinyin: "wǔ ge qiú  ",
          hanzi: "五个球",
        },

        {
          id: 1,
          pinyin: "zài jiā wán ",
          hanzi: "在家玩",
        },

        {
          id: 5,
          pinyin: "kàn diànshì ",
          hanzi: "看电视",
        },

        {
          id: 6,
          pinyin: " zǎoshang hǎo ",
          hanzi: "早上好",
        },

        {
          id: 9,
          pinyin: "qù qímǎ",
          hanzi: "去骑马",
        },
      ],
      nameString: ["leftList", "MiddleList", "rightList"],
      asideElementInfo: {
        leftList: [
          {
            id: 1,
            done: false,
            bgImg: require("@/assets/img/16-GAMES/G56-3-cards/lesson-15/2.svg"),
          },
          {
            id: 4,
            done: false,
            bgImg: require("@/assets/img/16-GAMES/G56-3-cards/lesson-15/5.svg"),
          },
          {
            id: 7,
            done: false,
            bgImg: require("@/assets/img/16-GAMES/G56-3-cards/lesson-15/6.svg"),
          },
        ],
        MiddleList: [
          {
            id: 2,
            done: false,
            bgImg: require("@/assets/img/16-GAMES/G56-3-cards/lesson-15/4.svg"),
          },
          {
            id: 5,
            done: false,
            bgImg: require("@/assets/img/16-GAMES/G56-3-cards/lesson-15/3.svg"),
          },
          {
            id: 8,
            done: false,
            bgImg: require("@/assets/img/16-GAMES/G56-3-cards/lesson-15/7.svg"),
          },
        ],
        rightList: [
          {
            id: 3,
            done: false,
            bgImg: require("@/assets/img/16-GAMES/G56-3-cards/lesson-15/1.svg"),
          },
          {
            id: 6,
            done: false,
            bgImg: require("@/assets/img/16-GAMES/G56-3-cards/lesson-15/9.svg"),
          },
          {
            id: 9,
            done: false,
            bgImg: require("@/assets/img/16-GAMES/G56-3-cards/lesson-15/8.svg"),
          },
        ],
      },
    };
  },
  components: {
    BingoGame,
  },
  methods: {
    changeStatus(item) {
      // console.log(item);
      for (let i = 0; i <= 2; i++) {
        this.asideElementInfo[this.nameString[i]].forEach((ele) => {
          if (ele.id === item.id) {
            ele.done = true;
          }
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.game-container {
  width: 100%;
  height: 100%;
}
</style>
