var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "game-container" },
    [
      _c("FindStrokeReviewGame", {
        attrs: {
          strokeInfoList: _vm.strokeInfoList,
          singleStrokeList: _vm.singleStrokeList,
          answerStepsArray: _vm.answerStepsArray,
          strokeType: 3,
          gridList: _vm.gridList,
          lessonNum: 16,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }